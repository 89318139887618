.mark{
    background-color: rgb(5,11,33);
    background: -moz-linear-gradient(90deg, rgba(5,11,33,1) 0%, rgba(114,115,116,1) 27%, rgba(240,243,246,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(5,11,33,1) 0%, rgba(114,115,116,1) 27%, rgba(240,243,246,1) 100%);
    background: linear-gradient(90deg, rgba(5,11,33,1) 0%, rgba(114,115,116,1) 27%, rgba(240,243,246,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#050b21",endColorstr="#f0f3f6",GradientType=1);;
}

.markTop{
    margin-left: 30%;
    height: 200px;
    text-align: center;
    align-items: center;
    
}
.backMark{
    border: solid 3px rgb(80, 23, 23);
    border-radius: 3%;
}
@media screen and (max-width: 915px) {
   
    .markTop{
       height: 85px;
       margin: auto;
       margin-left: 23%;
       margin-top: 35px;
       font-size: small;
    }
  
 }
