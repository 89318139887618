.fondo{
  background: rgb(236,72,0);
background: -moz-linear-gradient(14deg, rgba(236,72,0,1) 0%, rgba(219,2,2,0.5746673669467788) 20%, rgba(0,138,255,0.8155637254901961) 80%);
background: -webkit-linear-gradient(14deg, rgba(236,72,0,1) 0%, rgba(219,2,2,0.5746673669467788) 20%, rgba(0,138,255,0.8155637254901961) 80%);
background: linear-gradient(14deg, rgba(236,72,0,1) 0%, rgba(219,2,2,0.5746673669467788) 20%, rgba(0,138,255,0.8155637254901961) 80%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ec4800",endColorstr="#008aff",GradientType=1);
   
   
}
.logo2{
    margin-top: 35px;   
}



.divlogo{
    text-align: center;

}
.fotoProd2{
    height: 280px;
    width: 260px;
    align-items: center;
}

.markfoto{
    
}

.principalinicial{
   
    border: solid 3px rgb(36, 99, 194);
    border-radius: 5%;
   
}
@media screen and (max-width:980px){
     
    .logo2{
        height: 240px; 
        margin-top: 45px; 
    }
    .fotoProd2{
        height: 180px;
        width: 120px;
        align-items: center;
        margin-left: 8%;
    }
    .principalinicial{
        height: 200px;
        width: 150px;
    }
}
