

a{
    text-decoration: none;
    color: black;
    
}
h3{
    font-size: small;
}
p{
    font-size: large;
}

.botonModal{
    cursor: pointer;
    color: black;
    border: none;
   
   
    
   
}


.modalimg{
   text-align: center;
}
.contacto{
 padding: 0;
 align-items: center;
 margin-right: 15%;
}

