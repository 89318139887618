.maxxa{background-color:rgb(20,62,224);
    background: -moz-linear-gradient(90deg, rgba(20,62,224,1) 0%, rgba(140,18,184,1) 48%, rgba(219,20,138,1) 85%);
    background: -webkit-linear-gradient(90deg, rgba(20,62,224,1) 0%, rgba(140,18,184,1) 48%, rgba(219,20,138,1) 85%);
    background: linear-gradient(90deg, rgba(20,62,224,1) 0%, rgba(140,18,184,1) 48%, rgba(219,20,138,1) 85%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#143ee0",endColorstr="#db148a",GradientType=1);
     }
.maxxaTop{
    margin-top: 35px;
    height: 160px;
    margin-left: 450px;
}

.backMaxxa{
    border: .2pc solid  rgb(155, 4, 4);
    border-radius: 3%;
   
}
@media (max-width: 915px) {
    .card-img-top{
       height: 180px;
     
    }
    .maxxaTop{
       height: 65px;
       margin: auto;
       margin-left: 25%;
       margin-top: 35px;
       font-size: small;
    }
   
  
  
 }

