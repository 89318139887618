
.h1{
    text-align: center;
}
tr{
    text-align: center;
}
.boton-cerrar{
    align-items: center;
    text-align: center;
}