


.principal{
    border: .2pc solid rgb(36, 99, 194);
    border-radius: 5%;
}

.principal{
    transition: all .5s ease-in-out;
}
.principal:hover{transform: scale(1.2);}

h5{
    font-family: "baskerville";
}

.botonPie{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
@media (max-width: 960px) {
  
    .fotoProd{
     height: 180px;
    }
    .lenBody{
        width: auto;
    }
    }