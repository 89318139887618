
.botonPrincipal{
    background-color: rgb(248, 249, 250);
    color: black;
    border: none;
    outline: none;
    align-items: center;
    font-size: medium;
}
tr{
    text-align: center;
}
h1{
    text-align: center;
}
h2{
    text-align: center;
}
