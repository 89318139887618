


h4{
   font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-align: center;
}

@media (max-width: 967px) {
   
    .prinVid{
    
      margin-left: 10%;
      width: 100%;
     
    }
}
