.body{
 
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    vertical-align: top;
    vertical-align: bottom;
     height: 5px;}
.a{
    text-decoration: none;
    color: black;
    background-color: white;

    
}
.hr{
    border: solid 5px black;
    margin-top: 0;
}

.items{
    display: flex;
    height: 5px;
    justify-content: space-around;
    background-color: white;

      
   }

.botonPie{
    background-color:white;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: black;
    border: none;
    outline: none;
    margin-left: 10%;
    
}

.pieF{
    height: 23px;  
    background-color: white;
}
.pieF2{
    height: 23px;
    margin-left: -30px;
    background-color: white;
}
.cont{
  text-align: center;
  background-color: white;
 
}
.parr{
    margin-left: -25px;
    background-color: white;
}
.corr{
    margin-left: -140px;
}
@media (max-width: 967px) {
    .corr{
        margin-left: -50px;
        margin-right: 30px;
        
    }
    .parr{
        margin-left: -15px;
        font-size: medium;
    }
    .body{
      width: 100%;
    }
    .pieF2{
        height: 20px;
        margin-left: 70px;
   
}
.pieF{
    height: 20px;  
    font-size: xx-small;
    margin-left: 15px;
   
     }
     .items{
        margin-left: 10px;
        
     }
}