.CabeceraN{
    width:100%;
    padding:1em;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

   
}

.Cabecera-button{
    display:none;
    cursor: pointer;
}
.Cabecera-svg{
    width:2em;
    height:2em;
    fill:white;
}

.Cabecera-ul{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
}
.Cabecera-li{
    margin: 0 .5em;
}
@media screen and (max-width:480px){

    .Cabecera-button{

        display: flex;
        justify-content: flex-end;
        
    }

    .Cabecera-nav{
        width:100%;

        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        height:0vh;
        opacity: 0;
        overflow: hidden;
        transition: all .5s ease;
    }
    .Cabecera-nav.isActive{
        height:150px;
        opacity: 1;
    }
    .Cabecera-ul{
        flex-direction: column;
    }
    .Cabecera-li{
        margin: .5em 0;
    }
  
}
@media screen and (max-width:480px) {
    .CabeceraN{
        background-color: rgba(0,138,255,0.8155637254901961) 80%;
      
        width: 100%;
    }
   
}

@media screen and (min-width:768px){
    .CabeceraN{
        background-color: rgba(0,138,255,0.8155637254901961) 80%;
    }
}




.logo{
    
	height: 40px;
	width: 40px;
	padding: 0%;
	margin: -10%;
}

li{
list-style-type: none;
}

.float{
	margin-bottom: 17px;
	width:40px;
	height:40px;
	bottom:110px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	font-size:30px;
	
  
}

.h4i{
    
    color: rgb(34, 15, 202);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: oblique;
    border: solid 2px black;
    border-radius: 18%;

}
