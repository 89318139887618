.back{
    border: .2pc solid rgb(36, 99, 194);
    border-radius: 5%;
}
.card-img-top{
   height: 280px;

   
}
@media (max-width: 760px) {
   
.principal{

    height: 360px;
    width: 160px;
}
.fotoPro{
 height: 180px;
}
}