#bodywuw{
    background-color: rgb(236,72,0);
    background: -moz-linear-gradient(14deg, rgba(236,72,0,1) 30%, rgba(143,123,42,1) 97%, rgba(20,2,219,1) 99%);
    background: -webkit-linear-gradient(14deg, rgba(236,72,0,1) 30%, rgba(143,123,42,1) 97%, rgba(20,2,219,1) 99%);
    background: linear-gradient(14deg, rgba(236,72,0,1) 30%, rgba(143,123,42,1) 97%, rgba(20,2,219,1) 99%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ec4800",endColorstr="#1402db",GradientType=1);;
}

.wuwTop{
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 30%;
    height: 60px;
}
@media screen and (max-width: 915px) {
    .wuwTop{
        margin-top: 25%;
       height: 25px;
       margin-left: 30%;
    }
   }